import React from "react";

import { Link, graphql } from 'gatsby';
import BlogTitle from '../components/BlogTitle';

import '../components/AnimClaim.css';
import Layout from '../components/Layout';

export default ({ data }) => {
    console.log(data);
    return (
    <Layout>
        <div>
           
            <BlogTitle headerText='Wilkommen im Steinofen. Hier entsteht gerade ein kleiner Ort rund um LEGO®.'/>
            <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
            {data.allMarkdownRemark.edges.map(({ node }) => (
                <div key={node.id}>
                    <Link className="blog-post-title"
                        to={node.frontmatter.path}
                    >
                        <h3 style={{margin: '0 0 1rem 0'}}>
                            {node.frontmatter.title}{" "}
                            <span style={{color: '#bbb'}}>
                                - {node.frontmatter.date}
                            </span>
                        </h3>
                        <p>{node.excerpt}</p>
                    </Link>
                </div>
            ))}



        </div>
    </Layout>
    );
};

export const query = graphql`
    query {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC}
            filter: { fileAbsolutePath: { glob: "**/content/posts/*.md" } }
            ) {
            totalCount
            edges {
              node {
                id
                frontmatter {
                  title
                  date(formatString: "DD. MMMM YYYY", locale: "de")
                  path
                }
                excerpt
              }
            }
          }
    }
`;